import React from "react"
import PropTypes from "prop-types"
import Moment from "react-moment"

// Components
import ContentToHTML from "./content-html"

const ContentPDFList = props => {
  const { pdfItems } = props

  const handleDownload = el => {
    console.log(`Starting download for: ${el} ...`)
    typeof window !== "undefined" &&
      window.gtag("event", "Descargas", {
        event_category: "Documento",
        event_label: el,
      })
  }

  return (
    <div className="asm-content-pdf-items">
      {pdfItems.map((item, index) => {
        return (
          <div className="pdf-item-card p-2 mb-4" key={index}>
            <p className="card-title text-500 pl-3 mb-2">{item.titulo}</p>
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
              <p className="card-subtitle text-black-beta align-self-start align-self-md-center mt-0">
                Fecha de Publicación:&nbsp;
                <Moment format="LL">{item.fecha_publicacion}</Moment>
              </p>
              {item.archivo ? (
                <div className="align-self-end mt-2 mt-md-0">
                  {item.archivo.extension === "pdf" && item.es_descargable && (
                    <a
                      href={item.archivo.publicURL}
                      className="btn btn-link text-400 p-0 ml-auto"
                      role="button"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Abrir&nbsp;<i className="fa fa-share"></i>
                    </a>
                  )}
                  {item.archivo.publicURL && item.es_descargable && (
                    <a
                      href={item.archivo.publicURL}
                      className="btn btn-link text-400 p-0 ml-3"
                      role="button"
                      download={item.titulo}
                      onClick={() =>
                        handleDownload(`${item.titulo}.${item.archivo.extension}`)
                      }
                    >
                      Descargar&nbsp;<i className="fa fa-download"></i>
                    </a>
                  )}
                </div>
              ) : (
                <p
                  className="alert alert-warning align-self-end mb-0 mt-2 mt-md-0 "
                  role="alert"
                >
                  No hay un archivo adjunto!
                </p>
              )}
            </div>
            {item.descripcion ? (
              <ContentToHTML className="card-text" content={item.descripcion} />
            ) : null}
          </div>
        )
      })}
    </div>
  )
}

ContentPDFList.propTypes = {
  pdfItems: PropTypes.arrayOf(
    PropTypes.shape({
      titulo: PropTypes.string.isRequired,
      archivo: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
        extension: PropTypes.string.isRequired,
      }),
      descripcion: PropTypes.string,
      es_descargable: PropTypes.bool,
      fecha_publicacion: PropTypes.string.isRequired,
    })
  ).isRequired,
}

ContentPDFList.defaultProps = {
  pdfItems: [
    {
      titulo: `Nombre del Documento #1`,
      descripcion: `Lorem ipsum dolor sit amet, consectetur adipisicing elit.`,
      fecha_publicacion: `2020-12-16T17:00:00.000Z`,
      es_descargable: true,
      archivo: {
        publicURL: `/uploads/PDF_Example_pre_a00c251055.pdf`,
        extension: `pdf`,
      },
    },
    {
      titulo: `Nombre del Documento #2`,
      descripcion: `Lorem ipsum dolor sit amet, consectetur adipisicing elit.`,
      fecha_publicacion: `2020-12-16T17:00:00.000Z`,
      es_descargable: true,
      archivo: {
        publicURL: `/uploads/PDF_Example_pre_a00c251055.pdf`,
        extension: `pdf`,
      },
    },
  ],
}

export default ContentPDFList

import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

// Components
import CustomLink from "../elements/custom-link"

const logoHands = require("../../assets/images/icons/settings/hands-green.png")
const logoBoxes = require("../../assets/images/icons/settings/boxes-green.png")
const logoFontPlus = require("../../assets/images/icons/settings/font-plus-blue.png")
const logoFontMinus = require("../../assets/images/icons/settings/font-minus-blue.png")
const logoFontPlain = require("../../assets/images/icons/settings/font-plain-blue.png")
const logoBrightLeft = require("../../assets/images/icons/settings/bright-left-blue.png")
const logoBrightRight = require("../../assets/images/icons/settings/bright-right-blue.png")

const Settings = props => {
  const { secondaryLinks } = props

  const toggle_to_grayscale = () => {
    let bodyElement = document.getElementsByTagName("BODY")[0]
    bodyElement.classList.add("--grayscale")
  }

  const toggle_to_normal = () => {
    let bodyElement = document.getElementsByTagName("BODY")[0]
    bodyElement.classList.remove("--grayscale")
  }

  const toggle_letter_increase = () => {
    let htmlElement = document.documentElement
    let currentFontSize = parseFloat(htmlElement.style.fontSize)
      ? parseFloat(htmlElement.style.fontSize)
      : 100
    htmlElement.style.fontSize =
      currentFontSize < 130 ? currentFontSize + 5 + "%" : 130
  }

  const toggle_letter_decrease = () => {
    let htmlElement = document.documentElement
    let currentFontSize = parseFloat(htmlElement.style.fontSize)
      ? parseFloat(htmlElement.style.fontSize)
      : 100
    htmlElement.style.fontSize =
      currentFontSize > 70 ? currentFontSize - 5 + "%" : 70
  }

  const toggle_letter_to_normal = () => {
    let htmlElement = document.documentElement
    htmlElement.style.fontSize = ""
  }

  return (
    <div id="asm-settings" className="container d-none d-lg-block">
      <div className="row">
        <div className="col-sm">
          <div className="d-flex align-items-center justify-content-end">
            {secondaryLinks.map((item, index) => {
              return (
                <CustomLink
                  link={item}
                  customClass="accessibility-settings mr-2"
                  roleButton={true}
                  key={index}
                >
                  <img src={item.icono && item.icono.publicURL ? item.icono.publicURL : "http://placehold.it/75x75"} alt={item.nombre} />
                </CustomLink>
              )
            })}

            <Link
              className="accessibility-settings mr-2"
              to="/sitemap"
              role="button"
            >
              <img src={logoBoxes} alt="Logo Sitemap" />
            </Link>
            <Link
              className="accessibility-settings"
              to="/"
              role="button"
              onClick={toggle_letter_decrease}
            >
              <img src={logoFontMinus} alt="Logo Font Minus" />
            </Link>
            <Link
              className="accessibility-settings"
              to="/"
              role="button"
              onClick={toggle_letter_to_normal}
            >
              <img src={logoFontPlain} alt="Logo Font Plain" />
            </Link>
            <Link
              className="accessibility-settings"
              to="/"
              role="button"
              onClick={toggle_letter_increase}
            >
              <img src={logoFontPlus} alt="Logo Font Plus" />
            </Link>
            <Link
              className="accessibility-settings"
              to="/"
              role="button"
              onClick={toggle_to_normal}
            >
              <img src={logoBrightLeft} alt="Logo Bright Left" />
            </Link>
            <Link
              className="accessibility-settings"
              to="/"
              role="button"
              onClick={toggle_to_grayscale}
            >
              <img src={logoBrightRight} alt="Logo Bright Right" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

Settings.propTypes = {
  content: PropTypes.string,
  secondaryLinks: PropTypes.arrayOf(
    PropTypes.shape({
      nombre: PropTypes.string.isRequired,
      tipo: PropTypes.string.isRequired,
      link_id: PropTypes.string.isRequired,
      icono: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
}

Settings.defaultProps = {
  content: `url(http://placehold.it/1425x296)`,
  secondaryLinks: [
    {
      nombre: "Centro de Relevo",
      tipo: "Pagina",
      link_id: "/",
      icono: {
        publicURL: logoHands,
      },
    },
  ],
}

export default Settings

/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ title, description, image, type, lang }) {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            defaultImage: image
            defaultDescription: description
            author
            siteUrl
          }
        }
      }
    `
  )
  const {
    defaultTitle,
    defaultImage,
    defaultDescription,
    author,
    siteUrl,
  } = site.siteMetadata

  const seo = {
    type: type,
    titleTemplate: title
      ? title === defaultTitle
        ? title
        : `${title} | ${defaultTitle}`
      : defaultTitle,
    title: title || defaultTitle,
    siteUrl: `${siteUrl}${pathname}`,
    description: description || defaultDescription,
    cardImage: `${siteUrl}${image || defaultImage}`,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seo.title}
      titleTemplate={seo.titleTemplate}
      link={[
        {
          rel: "stylesheet",
          href:
            "https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap",
        },
      ]}
      script={[]}
      meta={[
        {
          name: `description`,
          content: seo.description,
        },
        {
          name: `author`,
          content: author,
        },
        {
          name: `image`,
          content: seo.cardImage,
        },
        // -- Open Graph Tags --
        {
          property: `og:title`,
          content: seo.title,
        },
        {
          property: `og:description`,
          content: seo.description,
        },
        {
          property: `og:image`,
          content: seo.cardImage,
        },
        {
          property: `og:url`,
          content: seo.siteUrl,
        },
        {
          property: `og:type`,
          content: seo.type,
        },
        // -- Twitter OG Tags --
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: seo.title,
        },
        {
          name: `twitter:description`,
          content: seo.description,
        },
        {
          name: `twitter:image`,
          content: seo.cardImage,
        },
      ]}
    />
  )
}

SEO.defaultProps = {
  title: null,
  image: null,
  description: null,
  type: `website`,
  lang: `es`,
}

SEO.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  lang: PropTypes.string,
}

export default SEO

import React from "react"
import PropTypes from "prop-types"

// Components
import PhotoGrid from "./photo-grid"

const ComponentAlbum = ({ mediaFotos }) => {
  return (
    <div className="asm-media-main">
      <PhotoGrid allPhotos={mediaFotos} />
    </div>
  )
}

ComponentAlbum.propTypes = {
  mediaFotos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      titulo: PropTypes.string.isRequired,
      fecha: PropTypes.string.isRequired,
      imagenes: PropTypes.arrayOf(
        PropTypes.shape({
          formats: PropTypes.object,
          url: PropTypes.string.isRequired,
        })
      ),
      descripcion: PropTypes.string.isRequired,
    })
  ).isRequired,
}

ComponentAlbum.defaultProps = {
  mediaFotos: [
    {
      id: `5ff3ac07ce1b5e19cb07092b`,
      slug: `jornada-de-vacunacion`,
      titulo: `Jornada de vacunación`,
      fecha: `2020-12-17T17:00:00.000Z`,
      imagenes: [
        {
          formats: {
            small: {
              publicURL: `/static/2d447e708a79cd7427f1fcce74c5bb4f/f77014d8352e6bc422177f80bf6b9921.png`,
            },
          },
          url: `https://asmed-staging.s3.amazonaws.com/jornada_vacunacion_4_3cb0ad5dc6.png`,
        },
      ],
      descripcion: `Lorem ipsum dolor sit amet`,
    },
  ],
}

export default ComponentAlbum

import React from "react"
import PropTypes from "prop-types"

// Components
import ItemSingle from "./item-single"

const ItemsGrid = ({ gridItems }) => {
  return (
    <div className="asm-items-grid">
      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 justify-content-center">
        {gridItems.map((item, index) => {
          return (
            <div className="col mb-4 mb-lg-0" key={index}>
              <ItemSingle item={item} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

ItemsGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      titulo: PropTypes.string.isRequired,
      descripcion: PropTypes.string.isRequired,
      imagen: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
      }).isRequired,
      link: PropTypes.shape({
        nombre: PropTypes.string.isRequired,
        tipo: PropTypes.string.isRequired,
        link_id: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
}

ItemsGrid.defaultProps = {
  gridItems: [
    {
      id: `5fc67108b2b37d04310d0a33`,
      titulo: `Item 1`,
      descripcion: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Inventore magnam tempore nobis dolores illo recusandae saepe minima quas eaque corporis provident sint voluptates.`,
      imagen: {
        publicURL: `/static/64a1c492a90e23e4aff745cc9debb823/4fe46ddf8269466efb3d53affd3f7e4c.png`,
      },
      link: {
        nombre: `Leer más`,
        tipo: `Pagina`,
        link_id: `5fe4770368cc14220204cae4`,
      },
    },
    {
      titulo: `Item 2`,
      descripcion: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae totam dolorem, nobis natus esse omnis odio culpa ad aperiam molestiae incidunt at enim consequatur porro ab asperiores itaque praesentium qui!`,
      imagen: {
        publicURL: `/static/64a1c492a90e23e4aff745cc9debb823/4fe46ddf8269466efb3d53affd3f7e4c.png`,
      },
    },
    {
      titulo: `Item 3`,
      descripcion: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae totam dolorem, nobis natus esse omnis odio culpa ad aperiam molestiae incidunt at enim consequatur porro ab asperiores itaque praesentium qui!`,
      imagen: {
        publicURL: `/static/64a1c492a90e23e4aff745cc9debb823/4fe46ddf8269466efb3d53affd3f7e4c.png`,
      },
    },
    {
      titulo: `Item 4`,
      descripcion: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae totam dolorem, nobis natus esse omnis odio culpa ad aperiam molestiae incidunt at enim consequatur porro ab asperiores itaque praesentium qui!`,
      imagen: {
        publicURL: `/static/64a1c492a90e23e4aff745cc9debb823/4fe46ddf8269466efb3d53affd3f7e4c.png`,
      },
    },
  ],
}

export default ItemsGrid

import React from "react"
import PropTypes from "prop-types"

// Components
import Carousel from "./carousel"
import PageMenu from "./page-menu"
import ItemsGrid from "./items-grid"
import ItemSingle from "./item-single"
import CustomLink from "./custom-link"
import ContentToHTML from "./content-html"
import AccordionLists from "./accordion-lists"
import ComponentAlbum from "./component-album"
import ComponentVideo from "./component-video"
import ComponentAudio from "./component-audio"
import ContentPDFRender from "./content-pdf-render"
import AccordionListsContainer from "./accordion-lists-tabs"

const CustomComponent = ({ component, index, noWrapper, pageId }) => {
  const renderComponentType = (component, index) => {
    switch (component.type) {
      case "Menu":
        return <PageMenu menu={component.data} pageId={pageId} />
      case "Texto":
        return <ContentToHTML content={component.data.contenido} />
      case "Card":
        return <ItemSingle item={component.data} />
      case "Grupo-Cards":
        return <ItemsGrid gridItems={component.data.cards} />
      case "Documento":
        return <ContentPDFRender pdfFile={component.data} />
      case "Banner":
        return <Carousel slides={component.data.slides} carouselId={index} />
      case "Album":
        return <ComponentAlbum mediaFotos={component.data.media_fotos}/>
      case "Video":
        return <ComponentVideo mediaVideos={component.data.media_videos}/>
      case "Audio":
        return <ComponentAudio mediaAudios={component.data.media_audios}/>
      case "Tab":
        return <AccordionListsContainer accordionListsTabs={component.data} tabId={index}/>
      case "Accordion":
        return <AccordionLists accordionLists={component.data} tabId={index} />
      case "Link":
        return (
          <CustomLink link={component.data} roleButton={true} customClass="btn btn-primary rounded-pill">
            {component.data.nombre}
            <i className="fa fa-long-arrow-right float-right"></i>
          </CustomLink>
        )
      default:
        return (
          <p className="text-red-alpha text-center my-3">
            Missing content! Contact support.
          </p>
        )
    }
  }

  const renderComponentWrapper = (component, index) => {
    switch (component.type) {
      case "Menu":
        return (
          <div className="container-fluid mb-5" id={component.data.id}>
            {renderComponentType(component, index)}
          </div>
        )
      case "Video":
        return (
          <div className="container my-5" id={component.data.id}>
            <div className="row justify-content-center">
              <div className="col-12">
                {renderComponentType(component, index)}
              </div>
            </div>
          </div>
        )
      default:
        return (
          <div className="container my-5" id={component.data.id}>
            <div className="row justify-content-center">
              <div className="col-12 col-sm-8">
                {renderComponentType(component, index)}
              </div>
            </div>
          </div>
        )
    }
  }

  return noWrapper
    ? renderComponentType(component, index)
    : renderComponentWrapper(component, index)
}

CustomComponent.propTypes = {
  component: PropTypes.shape({
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  pageId: PropTypes.string.isRequired,
  noWrapper: PropTypes.bool,
}

CustomComponent.defaultProps = {
  noWrapper: false,
}

export default CustomComponent

import { useStaticQuery, graphql } from "gatsby"

export const useAsmetPopup = () => {
  const data = useStaticQuery(
    graphql`
      query AsmetPopupQuery {
        allStrapiAsmetPopup {
          edges {
            node {
              strapiId
              titulo
              activado
              transparente
              contenido {
                id
                tipo_componente

                # Component Texto

                contenido

                # Component Banner

                slides {
                  tipo_componente
                  titulo
                  subtitulo
                  imagen {
                    publicURL
                  }
                  link {
                    nombre
                    tipo
                    link_id
                    icono {
                      publicURL
                    }
                  }
                }

                # Component Link

                nombre
                tipo
                link_id
                icono {
                  publicURL
                }

                # Component Video

                media_videos {
                  id
                  slug
                  titulo
                  link
                  descripcion
                  publicado_en
                }
              }
            }
          }
        }
      }
    `
  )
  return data.allStrapiAsmetPopup.edges[0].node
}

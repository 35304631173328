import React, { useState } from "react"
import PropTypes from "prop-types"
import Moment from "react-moment"
// @react-pdf
import { SizeMe } from "react-sizeme" // @size-me to get Container size
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
// Components
import ContentToHTML from "./content-html"

// import samplePDF from "../../assets/data/pdf-sample.pdf"

const ContentToPDF = props => {
  const { pdfFile } = props

  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1) // Setting 1 to show first page

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  const handleDownload = el => {
    console.log(`Starting download for: ${el} ...`)
    typeof window !== "undefined" &&
      window.gtag("event", "Descargas", {
        event_category: "Documento",
        event_label: el,
      })
  }

  return (
    <div className="asm-content-pdf-render">
      <div className="react-pdf__Info row">
        <div className="col-12">
          <h6 className="text-600 d-sm-inline">{pdfFile.titulo}</h6>
          <small className="float-right text-muted text-right mb-3">
            Fecha de Publicación:&nbsp;
            <Moment format="LL">{pdfFile.fecha_publicacion}</Moment>
          </small>
        </div>

        <div className="col-12">
          {pdfFile.descripcion ? (
            <ContentToHTML content={pdfFile.descripcion} />
          ) : null}
        </div>
      </div>
      {pdfFile.archivo && (
        <div className="react-pdf__Controls d-flex align-items-center justify-content-between px-5 mt-4">
          {pdfFile.archivo.extension === "pdf" && (
            <p className="mb-0">
              Página {pageNumber || (numPages ? 1 : "--")} de {numPages || "--"}
            </p>
          )}
          <div className="mb-3 mb-sm-0 ml-auto">
            {pdfFile.archivo.extension === "pdf" && (
              <div className="d-inline">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                >
                  <i className="fa fa-chevron-left"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-primary ml-1"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                >
                  <i className="fa fa-chevron-right"></i>
                </button>
              </div>
            )}
            {pdfFile.archivo.publicURL && pdfFile.es_descargable && (
              <a
                href={pdfFile.archivo.publicURL}
                className="btn btn-primary ml-1"
                role="button"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-arrows-alt"></i>
              </a>
            )}
            {pdfFile.archivo.publicURL && pdfFile.es_descargable && (
              <a
                href={pdfFile.archivo.publicURL}
                className="btn btn-primary ml-1"
                role="button"
                download={pdfFile.titulo}
                onClick={() =>
                  handleDownload(`${pdfFile.titulo}.${pdfFile.archivo.extension}`)
                }
              >
                <i className="fa fa-download"></i>
              </a>
            )}
          </div>
        </div>
      )}
      {pdfFile.archivo && pdfFile.archivo.publicURL ? (
        pdfFile.archivo.extension === "pdf" ? (
          <SizeMe>
            {({ size }) => (
              <Document
                file={pdfFile.archivo.publicURL}
                onLoadSuccess={onDocumentLoadSuccess}
                options={{ workerSrc: "pdf.worker.js" }}
                externalLinkTarget="_blank"
              >
                <Page
                  pageNumber={pageNumber}
                  width={size.width ? size.width - 70 : 1}
                />
              </Document>
            )}
          </SizeMe>
        ) : (
          <p
            className="alert alert-primary text-justify mr-5 mt-3"
            role="alert"
          >
            <i
              className={
                "fa mr-2 " +
                (pdfFile.archivo.extension.startsWith("xl")
                  ? "fa-file-excel-o"
                  : pdfFile.archivo.extension.startsWith("do")
                  ? "fa-file-word-o"
                  : pdfFile.archivo.extension.startsWith("pp")
                  ? "fa-file-powerpoint-o"
                  : "fa-file-o")
              }
            ></i>
            No hay previsualización para el archivo adjunto. En su lugar puedes
            decargarlo!
          </p>
        )
      ) : (
        <p className="alert alert-warning mt-3" role="alert">
          No hay un archivo adjunto!
        </p>
      )}
    </div>
  )
}

ContentToPDF.propTypes = {
  pdfFile: PropTypes.shape({
    titulo: PropTypes.string.isRequired,
    archivo: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
      extension: PropTypes.string.isRequired,
    }).isRequired,
    descripcion: PropTypes.string,
    es_descargable: PropTypes.bool,
    fecha_publicacion: PropTypes.string.isRequired,
  }).isRequired,
}

ContentToPDF.defaultProps = {
  pdfFile: {
    titulo: `Nombre del Documento`,
    descripcion: `Lorem ipsum dolor sit amet, consectetur adipisicing elit.`,
    fecha_publicacion: `2020-12-16T17:00:00.000Z`,
    es_descargable: true,
    archivo: {
      publicURL: `/uploads/PDF_Example_pre_a00c251055.pdf`,
      extension: `pdf`,
    },
  },
}

export default ContentToPDF

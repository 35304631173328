import React from "react"
import PropTypes from "prop-types"

// Components
import CustomComponent from "../../components/elements/custom-component"

// Hooks
import { useGroupedComponents } from "../../hooks/use-grouped-components"

const HomePopUp = props => {
  const { popUp } = props
  const groupedComponents = useGroupedComponents(popUp.contenido)

  return (
    <div
      id="asm-homeModal"
      className="modal fade"
      tabIndex="-1"
      aria-hidden="true"
      aria-labelledby="asm-homeModalLabel"
      {...(popUp.activado ? { enabled: "true" } : {})}
    >
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div
          className={
            "modal-content " + (popUp.transparente ? "modal-dark" : "")
          }
        >
          {popUp.titulo && (
            <div className="modal-header border-0">
              <h6 className="modal-title" id="asm-homeModalLabel">
                {popUp.titulo}
              </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}

          <div className="modal-body py-2">
            {groupedComponents.map((item, index) => {
              return (
                <div key={index} className="mb-3 d-flex justify-content-center">
                  <CustomComponent
                    component={item}
                    index={index}
                    key={index}
                    noWrapper={true}
                    pageId={popUp.strapiId}
                  />
                </div>
              )
            })}
          </div>

          <div className="modal-footer border-0">
            <button
              type="button"
              className="btn px-3 mx-auto btn-light"
              data-dismiss="modal"
            >
              <u>Cerrar esta ventana</u>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

HomePopUp.propTypes = {
  popUp: PropTypes.shape({
    strapiId: PropTypes.string.isRequired,
    titulo: PropTypes.string,
    activado: PropTypes.bool.isRequired,
    transparente: PropTypes.bool.isRequired,
    contenido: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        tipo_componente: PropTypes.string.isRequired,
        // Case Component Texto
        contenido: PropTypes.string,
        // Case Component Banner
        slides: PropTypes.arrayOf(PropTypes.object),
        // Case Component Link
        nombre: PropTypes.string,
        tipo: PropTypes.string,
        link_id: PropTypes.string,
        icono: PropTypes.shape({
          publicURL: PropTypes.string.isRequired,
        }),
        // Case Component Video
        media_videos: PropTypes.arrayOf(PropTypes.object),
      })
    ).isRequired,
  }).isRequired,
}

HomePopUp.defaultProps = {
  popUp: {
    strapiId: `602d46cabc7505028`,
    titulo: `Actualiza tus datos`,
    activado: false,
    transparente: false,
    contenido: [
      {
        id: `602d46cabc7505028104`,
        tipo_componente: `Texto`,
        contenido: `<p>Lorem ipsum dolor sit amet!</p>`,
      },
    ],
  },
}

export default HomePopUp

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

// Components
import CustomLink from "../elements/custom-link"

const logoSupersalud = require("../../assets/images/logos/supersalud-logo.png")

const Footer = props => {
  const { socialNetworks } = props

  return (
    <footer id="asm-footer" className="container-fluid py-3 bg-black-alpha">
      <div className="container">
        <div className="row">
          <div className="col-4 col-md order-2 order-md-1 footer-main-logo d-flex align-items-center">
            <Link to="/">
              <img src={logoSupersalud} alt="Logo Super Salud" />
            </Link>
          </div>

          <div className="col-12 col-md order-1 order-md-2 footer-social-links mb-4 mb-md-0">
            {socialNetworks.map((item, index) => {
              return (
                <CustomLink link={item} customClass="mr-3" key={index}>
                  <img src={item.icono && item.icono.publicURL ? item.icono.publicURL : "http://placehold.it/75x75"} alt={item.nombre} />
                </CustomLink>
              )
            })}
          </div>

          <div className="col-8 col-md order-3 order-md-3 footer-content px-0 px-md-3">
            <p className="mb-0">
              Entidad de derecho privado, sin ánimo de lucro, reconocida
              mediante Personería Jurídica No 3393 del 23 de noviembre de 1995.
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  socialNetworks: PropTypes.arrayOf(
    PropTypes.shape({
      nombre: PropTypes.string.isRequired,
      tipo: PropTypes.string.isRequired,
      link_id: PropTypes.string.isRequired,
      icono: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
}

Footer.defaultProps = {
  socialNetworks: [
    {
      nombre: "Twitter",
      tipo: "Externo",
      link_id: "/",
      icono: {
        publicURL:
          "/static/70a607edb2a909936b47d4dd978a4209/e270282bad4ab2907233f099d1db3a9c.png",
      },
    },
  ],
}

export default Footer

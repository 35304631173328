import React from "react"
import PropTypes from "prop-types"

// Components
import CustomLink from "./custom-link"

const CarouselSlides = props => {
  const { slides, carouselId } = props

  return (
    <div className="asm-carousel-slides">
      <div
        id={"carouselExampleCaptions" + carouselId}
        className="carousel slide"
        data-ride="carousel"
      >
        <ol className="carousel-indicators">
          {slides.map((item, index) => {
            return (
              <li
                data-target={"#carouselExampleCaptions" + carouselId}
                data-slide-to={index}
                className={index === 0 ? "active" : ""}
                key={index}
              ></li>
            )
          })}
        </ol>
        <div className="carousel-inner">
          {slides.map((item, index) => {
            return (
              <div
                className={"carousel-item" + (index === 0 ? " active" : "")}
                key={index}
              >
                <img
                  src={item.imagen ? item.imagen.publicURL : "http://placehold.it/800x465"}
                  className="d-block w-100"
                  alt={item.titulo}
                />
                <div className="carousel-caption d-none d-md-block">
                  <h5 className="carousel-title">{item.titulo}</h5>
                  {item.subtitulo ? <p>{item.subtitulo}</p> : null}
                  {item.link ? (
                    <CustomLink
                      link={item.link}
                      customClass="btn btn-asm-white-alpha text-red-alpha px-5 py-2 mb-2"
                      stretchedLink={true}
                      roleButton={true}
                    >
                      {item.link.nombre}
                      <i className="fa fa-long-arrow-right ml-3"></i>
                    </CustomLink>
                  ) : null}
                </div>
              </div>
            )
          })}
        </div>
        <a
          className="carousel-control-prev"
          href={"#carouselExampleCaptions" + carouselId}
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href={"#carouselExampleCaptions" + carouselId}
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </div>
  )
}

CarouselSlides.propTypes = {
  carouselId: PropTypes.number,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      titulo: PropTypes.string.isRequired,
      subtitulo: PropTypes.string,
      imagen: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
      }),
      link: PropTypes.shape({
        nombre: PropTypes.string.isRequired,
        tipo: PropTypes.string.isRequired,
        link_id: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
}

CarouselSlides.defaultProps = {
  carouselId: 0,
  slides: [
    {
      titulo: `Titulo Slide`,
      subtitulo: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Inventore magnam tempore nobis dolores illo recusandae saepe minima quas eaque corporis provident sint voluptates.`,
      imagen: {
        publicURL: `/static/64a1c492a90e23e4aff745cc9debb823/4fe46ddf8269466efb3d53affd3f7e4c.png`,
      },
      link: {
        nombre: `Link`,
        tipo: `Pagina`,
        link_id: `5fe4770368cc14220204cae4`,
      },
    },
    {
      titulo: `Titulo Slide`,
      subtitulo: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Inventore magnam tempore nobis dolores illo recusandae saepe minima quas eaque corporis provident sint voluptates.`,
      imagen: {
        publicURL: `/static/64a1c492a90e23e4aff745cc9debb823/4fe46ddf8269466efb3d53affd3f7e4c.png`,
      },
    },
  ],
}

export default CarouselSlides

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

// Components
import CustomLink from "../elements/custom-link"
import Settings from "./settings"

const iconPeople = require("../../assets/images/icons/afiliados.png")
const logoSearch = require("../../assets/images/icons/tools/search-gray.png")
const logoAsmetSalud = require("../../assets/images/logos/asmet-white_white@2x.png")
const logoOficinaVirtualBlanco = require("../../assets/images/logos/oficina-virtual-logo.png")

const TopBar = props => {
  const { primaryLinks, socialNetworks, barSettings, secondaryLinks } = props

  return (
    <nav
      id="asm-topbar"
      className="navbar navbar-expand-lg navbar-light p-0 mb-3 d-none d-lg-block"
    >
      <div className="container">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#topbarResponsive"
          aria-controls="topbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="topbarResponsive">
          {barSettings ? (
            <ul className="navbar-nav mr-auto nav-top-links">
              <li className="nav-item main-logo">
                <Link className="nav-link p-3 pt-4" to="/">
                  <img src={logoAsmetSalud} alt="Logo Asmet Salud" />
                </Link>
              </li>
            </ul>
          ) : (
            <ul className="navbar-nav mr-auto nav-top-links">
              {primaryLinks.map((item, index) => {
                return item.nombre === "Oficina Virtual" ? (
                  <li className="nav-item of-virtual px-4 mr-4" key={index}>
                    <CustomLink link={item} customClass="nav-link">
                      <img src={item.icono && item.icono.publicURL ? item.icono.publicURL : "http://placehold.it/75x75"} alt={item.nombre} />
                    </CustomLink>
                    <i className="fa fa-angle-right text-white-alpha"></i>
                  </li>
                ) : (
                  <li className="nav-item mr-1" key={index}>
                    <CustomLink link={item} customClass="nav-link">
                      <img
                        src={item.icono && item.icono.publicURL ? item.icono.publicURL : "http://placehold.it/75x75"} alt={item.nombre}
                        className="mr-2"
                      />
                      {item.nombre}
                    </CustomLink>
                  </li>
                )
              })}
            </ul>
          )}

          {barSettings && (
            <ul className="navbar-nav nav-settings py-4">
              <li className="nav-item">
                <Settings secondaryLinks={secondaryLinks}/>
              </li>
            </ul>
          )}

          {barSettings || (
            <ul className="navbar-nav nav-social-links">
              {socialNetworks.map((item, index) => {
                return (
                  <li className="nav-item" key={index}>
                    <CustomLink link={item} customClass="nav-link">
                      <img src={item.icono && item.icono.publicURL ? item.icono.publicURL : "http://placehold.it/75x75"} alt={item.nombre} />
                    </CustomLink>
                  </li>
                )
              })}
            </ul>
          )}

          <ul className="navbar-nav nav-search-link">
            <li className="nav-item">
              <Link className="nav-link" to="/search">
                <img src={logoSearch} alt="Logo Search" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

TopBar.propTypes = {
  barSettings: PropTypes.bool,
  primaryLinks: PropTypes.arrayOf(
    PropTypes.shape({
      nombre: PropTypes.string.isRequired,
      tipo: PropTypes.string.isRequired,
      link_id: PropTypes.string.isRequired,
      icono: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  socialNetworks: PropTypes.arrayOf(
    PropTypes.shape({
      nombre: PropTypes.string.isRequired,
      tipo: PropTypes.string.isRequired,
      link_id: PropTypes.string.isRequired,
      icono: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  secondaryLinks: PropTypes.arrayOf(
    PropTypes.shape({
      nombre: PropTypes.string.isRequired,
      tipo: PropTypes.string.isRequired,
      link_id: PropTypes.string.isRequired,
      icono: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
}

TopBar.defaultProps = {
  barSettings: false,
  primaryLinks: [
    {
      nombre: "Oficina Virtual",
      tipo: "Pagina",
      link_id: "/",
      icono: {
        publicURL: logoOficinaVirtualBlanco,
      },
    },
    {
      nombre: "Afiliados",
      tipo: "Pagina",
      link_id: "/",
      icono: {
        publicURL: iconPeople,
      },
    },
    {
      nombre: "Prestadores",
      tipo: "Pagina",
      link_id: "/",
      icono: {
        publicURL: iconPeople,
      },
    },
    {
      nombre: "Empleadores",
      tipo: "Pagina",
      link_id: "/",
      icono: {
        publicURL: iconPeople,
      },
    },
  ],
  socialNetworks: [
    {
      nombre: "Twitter",
      tipo: "Externo",
      link_id: "/",
      icono: {
        publicURL:
          "/static/70a607edb2a909936b47d4dd978a4209/ee484b0c99ec904f199eef06b18a61ce.png",
      },
    },
  ],
  secondaryLinks: [
    {
      nombre: "Centro de Relevo",
      tipo: "Pagina",
      link_id: "/",
      icono: {
        publicURL: iconPeople,
      },
    },
  ],
}

export default TopBar

import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

// Components
import ContentPDFItems from "./content-pdf-items"
import ContentPDFRender from "./content-pdf-render"
import ContentToHTML from "../elements/content-html"

const AccordionLists = props => {
  const { accordionLists, tabId } = props

  return (
    <div
      id={"accordionLists" + tabId}
      className="accordion asm-accordion-lists"
    >
      {accordionLists.map((item, index) => {
        return (
          <div className="card" key={index}>
            <div className="card-header p-0" id={"heading" + tabId + index}>
              <Link
                to={`/`}
                className={
                  "btn btn-link rounded-0 btn-block text-left " +
                  (accordionLists.length === 1 ? "" : "collapsed")
                }
                role="button"
                data-toggle="collapse"
                data-target={"#collapse" + tabId + index}
                aria-expanded={accordionLists.length === 1 ? "true" : "false"}
                aria-controls={"collapse" + tabId + index}
              >
                {item.titulo}
                <i className="fa fa-angle-down float-right"></i>
              </Link>
            </div>

            <div
              id={"collapse" + tabId + index}
              className={
                "collapse " + (accordionLists.length === 1 ? "show" : "")
              }
              aria-labelledby={"heading" + tabId + index}
              data-parent={"#accordionLists" + tabId}
            >
              <div className="card-body pt-4 pb-5">
                {item.contenido ? (
                  <ContentToHTML content={item.contenido} />
                ) : item.documentacion && item.documentacion.length === 1 ? (
                  <ContentPDFRender pdfFile={item.documentacion[0]} />
                ) : (
                  <ContentPDFItems pdfItems={item.documentacion}/>
                )}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

AccordionLists.propTypes = {
  tabId: PropTypes.number,
  accordionLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      titulo: PropTypes.string.isRequired,
      // If Lista Desplegable tipo Texto
      contenido: PropTypes.string,
      // If Lista Desplegable tipo Documentación
      documentacion: PropTypes.arrayOf(
        PropTypes.shape({
          titulo: PropTypes.string.isRequired,
          archivo: PropTypes.shape({
            publicURL: PropTypes.string.isRequired,
            extension: PropTypes.string.isRequired,
          }),
          descripcion: PropTypes.string,
          es_descargable: PropTypes.bool,
          fecha_publicacion: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
}

AccordionLists.defaultProps = {
  tabId: 0,
  accordionLists: [
    {
      id: `5fc67108b2b37d04310d0a30`,
      titulo: `Titulo Lista #1`,
      contenido: `<h1>Some Heading</h1><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>`,
    },
    {
      id: `5fc67108b2b37d04310d0a31`,
      titulo: `Titulo Lista #2`,
      contenido: `<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>`,
    },
    {
      id: `5fc67108b2b37d04310d0a32`,
      titulo: `Titulo Lista #3`,
      contenido: `<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>`,
    },
    {
      id: `5fc67108b2b37d04310d0a33`,
      titulo: `Titulo Lista #4`,
      documentacion: [
        {
          titulo: `Nombre del Documento`,
          descripcion: `Lorem ipsum dolor sit amet, consectetur adipisicing elit.`,
          fecha_publicacion: `2020-12-16T17:00:00.000Z`,
          es_descargable: true,
          archivo: {
            publicURL: `/uploads/PDF_Example_pre_a00c251055.pdf`,
            extension: `pdf`,
          },
        },
      ],
    },
  ],
}

export default AccordionLists

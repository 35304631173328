import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const CustomLink = ({
  link,
  customClass,
  stretchedLink,
  roleButton,
  children,
}) => {
  return link.tipo === "Externo" ? (
    <a
      href={link.link_id}
      target="_blank"
      rel="noopener noreferrer"
      className={customClass + (stretchedLink ? " stretched-link" : "")}
      {...(roleButton ? { role: "button" } : {})}
    >
      {children}
    </a>
  ) : (
    <Link
      to={linkType(link)}
      className={customClass + (stretchedLink ? " stretched-link" : "")}
      {...(roleButton ? { role: "button" } : {})}
    >
      {children}
    </Link>
  )
}

const linkType = link => {
  switch (link.tipo) {
    case "Pagina":
      return `/pagina/${link.link_id}`
    case "Blog":
      return link.link_id === "/" ? `/blog` : `/blog/${link.link_id}`
    case "Evento":
      return `/events/${link.link_id}`
    case "Noticia":
      return `/noticias/${link.link_id}`
    case "Video":
      return `/videos/${link.link_id}`
    case "Fotos":
      return `/fotos/${link.link_id}`
    case "Audios":
      return `/media`
    case "Grupo":
      return `/`
    default:
      return `/`
  }
}

CustomLink.propTypes = {
  children: PropTypes.node,
  customClass: PropTypes.string,
  roleButton: PropTypes.bool,
  stretchedLink: PropTypes.bool,
  link: PropTypes.shape({
    nombre: PropTypes.string.isRequired,
    tipo: PropTypes.string.isRequired,
    link_id: PropTypes.string.isRequired,
  }).isRequired,
}

CustomLink.defaultProps = {
  children: ``,
  customClass: ``,
  stretchedLink: false,
  link: {
    nombre: `Read more`,
    tipo: `Pagina`,
    link_id: `5fe4770368cc14220204cae4`,
  },
}

export default CustomLink

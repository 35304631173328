import React from "react"
import PropTypes from "prop-types"
import Moment from "react-moment"
import "moment/locale/es"

import Helmet from "react-helmet"
import { withPrefix } from "gatsby"

import Seo from "./seo"
// Components
import Popup from "./main/popup"
import Navbar from "./main/navbar"
import TopBar from "./main/top-bar"
import GovBar from "./main/gov-bar"
import Footer from "./main/footer"
import Cookies from "./main/cookies"
import Settings from "./main/settings"
import ContactUs from "./main/contact"

// Internal Styles
import "../assets/styles/scss/main.scss"

// Hooks
import { useAsmetHome } from "../hooks/use-asmet-home"
import { useAsmetPopup } from "../hooks/use-asmet-popup"

Moment.globalLocale = "es"

const Layout = ({ simpleLayout, meta, children }) => {
  const asmetHome = useAsmetHome()
  const asmetPopup = useAsmetPopup()

  const { og_titulo, og_imagen, og_descripcion, og_tipo } = meta || {}

  return (
    <div>
      <Seo
        {...(og_tipo ? { type: og_tipo } : {})}
        {...(og_titulo ? { title: og_titulo } : {})}
        {...(og_imagen ? { image: og_imagen.publicURL } : {})}
        {...(og_descripcion ? { description: og_descripcion } : {})}
      />
      <GovBar />
      <TopBar
        barSettings={simpleLayout}
        socialNetworks={asmetHome.redes_sociales}
        primaryLinks={asmetHome.enlaces_principales}
        secondaryLinks={asmetHome.enlaces_secundarios}
      />
      {simpleLayout || (
        <Settings secondaryLinks={asmetHome.enlaces_secundarios} />
      )}
      {simpleLayout || (
        <Navbar
          menuPrincipal={asmetHome.menu_principal}
          socialNetworks={asmetHome.redes_sociales}
          primaryLinks={asmetHome.enlaces_principales}
          contacto={asmetHome.contacto}
        />
      )}
      <main className="asm-main">{children}</main>
      <ContactUs footer={asmetHome.footer} />
      <Footer socialNetworks={asmetHome.redes_sociales} />
      <Popup popUp={asmetPopup} />
      <Cookies />
      <Helmet>
        <script src={withPrefix("init-chat.js")} type="text/javascript" />
      </Helmet>
    </div>
  )
}

Layout.propTypes = {
  meta: PropTypes.shape({
    og_tipo: PropTypes.string,
    og_titulo: PropTypes.string,
    og_descripcion: PropTypes.string,
    og_imagen: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }),
  }),
  simpleLayout: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  simpleLayout: false,
}

export default Layout

import React from "react"
import PropTypes from "prop-types"
import Moment from "react-moment"
import { Link } from "gatsby"

// Components
import ContentToHTML from "./content-html"

const AudioSingle = ({ singleAudio }) => {
  return (
    <div className="card rounded-0 border-0 mb-5 mb-sm-0">
      <audio controls className="w-100" src={singleAudio.archivo.publicURL}>
        <track default kind="captions" srcLang="en" />
        Su navegador no soporta elementos de
        <code>audio</code>.
      </audio>
      <div className="card-body px-0 px-sm-4">
        {singleAudio.blog_subcategorias && singleAudio.blog_subcategorias.length > 0 && (
          <div className="card-tags mb-3">
            {singleAudio.blog_subcategorias.map((c, i) => (
              <Link
                key={i}
                to={`/audios?t=Todos&c=${c.nombre}`}
                className="badge badge-info mr-1"
              >
                {c.nombre}
              </Link>
            ))}
          </div>
        )}
        <h2 className="card-title mb-3 text-600">{singleAudio.titulo}</h2>
        {singleAudio.tipo && (
          <Link
            to={`/audios?t=${singleAudio.tipo}&c=Todos`}
            className="d-block mb-2"
          >
            <i className="fa fa-headphones mr-1"></i>
            {singleAudio.tipo}
          </Link>
        )}
        <small className="card-subtitle d-block">
          Publicado el&nbsp;
          <Moment format="LL">{singleAudio.publicado_en}</Moment>
        </small>
        <div className="card-text">
          <ContentToHTML
            content={
              singleAudio.descripcion
                ? singleAudio.descripcion
                : "No hay descripción!"
            }
          />
        </div>
      </div>
    </div>
  )
}

AudioSingle.propTypes = {
  singleAudio: PropTypes.shape({
    titulo: PropTypes.string.isRequired,
    archivo: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }).isRequired,
    descripcion: PropTypes.string.isRequired,
    publicado_en: PropTypes.string.isRequired,
    tipo: PropTypes.string,
    blog_subcategorias: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string.isRequired,
        nombre: PropTypes.string.isRequired,
      })
    ),
  }),
}

AudioSingle.defaultProps = {
  singleAudio: {
    titulo: `Diabetes Gestacional`,
    archivo: {
      publicURL: `/static/0c481e87f2774b1bd41a0a70d9b70d11/4d1eaa21992d880810d7f98e7ceb959d.mp3`,
    },
    descripcion: `Acompáñanos para hablar sobre #DiabetesGestacional. Si estás en #embarazo 🤰🏻o conoces a alguien que le pueda interesar esta información ¡Comparte! #Live #EnfermedadesDelEmbarazo #Gestación #MaternoPerinatal`,
    publicado_en: `2020-12-17T17:00:00.000Z`,
    tipo: `Entrevista`,
    blog_subcategorias: [
      {
        slug: `vih`,
        nombre: `VIH`,
      },
    ],
  },
}

export default AudioSingle

import { useStaticQuery, graphql } from "gatsby"

export const useAsmetHome = () => {
  const data = useStaticQuery(
    graphql`
      query AsmetHomeQuery {
        allStrapiAsmetHome {
          edges {
            node {
              strapiId
              titulo
              meta {
                og_titulo
                og_imagen {
                  publicURL
                }
                og_descripcion
              }
              enlaces_principales {
                nombre
                tipo
                link_id
                icono {
                  publicURL
                }
              }
              redes_sociales {
                nombre
                tipo
                link_id
                icono {
                  publicURL
                }
              }
              enlaces_secundarios {
                nombre
                tipo
                link_id
                icono {
                  publicURL
                }
              }
              menu_principal {
                nombre
                titulo {
                  nombre
                  tipo
                  link_id
                  icono {
                    publicURL
                  }
                }
                links {
                  nombre
                  tipo
                  link_id
                  icono {
                    publicURL
                  }
                }
              }
              promocion_principal {
                titulo
                subtitulo
                link {
                  nombre
                  tipo
                  link_id
                  icono {
                    publicURL
                  }
                }
                imagen {
                  publicURL
                }
              }
              banner_principal {
                titulo
                slides {
                  titulo
                  link {
                    nombre
                    tipo
                    link_id
                    icono {
                      publicURL
                    }
                  }
                  imagen {
                    publicURL
                  }
                }
              }
              menu_secundario {
                nombre
                tipo
                link_id
                icono {
                  publicURL
                }
              }
              banner_secundario {
                titulo
                slides {
                  titulo
                  subtitulo
                  link {
                    nombre
                    tipo
                    link_id
                    icono {
                      publicURL
                    }
                  }
                  imagen {
                    publicURL
                  }
                }
              }
              enlaces_rapidos {
                nombre
                tipo
                link_id
                icono {
                  publicURL
                }
              }
              tema_destacado {
                titulo
                subtitulo
                link {
                  nombre
                  tipo
                  link_id
                  icono {
                    publicURL
                  }
                }
                imagen {
                  publicURL
                }
              }
              instituciones {
                nombre
                tipo
                link_id
                icono {
                  publicURL
                }
              }
              footer {
                id
                nombre
                titulo {
                  nombre
                  tipo
                  link_id
                  icono {
                    publicURL
                  }
                }
                links {
                  nombre
                  tipo
                  link_id
                  icono {
                    publicURL
                  }
                }
              }
              contacto {
                num_fijo
                num_celular
                sede_nacional
                enlaces_contacto {
                  nombre
                  tipo
                  link_id
                  icono {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return data.allStrapiAsmetHome.edges[0].node
}

import React, { useState } from "react"
import PropTypes from "prop-types"

// Components
import VideoSingle from "./video-single"
import VideoSidebar from "./video-sidebar"

const ComponentVideo = ({ mediaVideos }) => {
  const [singleVideo, setSingleVideo] = useState(mediaVideos[0])

  function handleSingleVideo(el) {
    setSingleVideo(el)
  }

  return (
    <div className="asm-media-main">
      <div className="row justify-content-center">
        <div className="asm-media-single col-12 col-md-7 col-lg-8">
          <VideoSingle singleVideo={singleVideo} />
        </div>
        {mediaVideos.length > 1 && (
          <div className="asm-media-sidebar col-12 col-md-5 col-lg-4">
            <VideoSidebar
              singleVideoID={singleVideo.id}
              sidebarVideos={mediaVideos}
              handleSingleVideo={el => handleSingleVideo(el)}
            />
          </div>
        )}
      </div>
    </div>
  )
}

ComponentVideo.propTypes = {
  mediaVideos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      titulo: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      descripcion: PropTypes.string.isRequired,
      publicado_en: PropTypes.string.isRequired,
      tipo: PropTypes.string,
      blog_subcategorias: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string.isRequired,
          nombre: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
}

ComponentVideo.defaultProps = {
  mediaVideos: [
    {
      id: `5ff3ac07ce1b5e19cb07092b`,
      titulo: `Diabetes Gestacional`,
      slug: `diabetes-gestacional`,
      link: `4r0IqcCBBVY`,
      descripcion: `Acompáñanos para hablar sobre #DiabetesGestacional. Si estás en #embarazo 🤰🏻o conoces a alguien que le pueda interesar esta información ¡Comparte! #Live #EnfermedadesDelEmbarazo #Gestación #MaternoPerinatal`,
      publicado_en: `2020-12-17T17:00:00.000Z`,
      tipo: `Otro`,
      blog_subcategorias: [
        {
          slug: `vih`,
          nombre: `VIH`,
        },
      ],
    },
  ],
}

export default ComponentVideo

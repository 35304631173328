import { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import _ from "lodash"

export const usePageHierarchy = strapiId => {
  const [pages, setPages] = useState([])

  const data = useStaticQuery(
    graphql`
      query PageHierarchy {
        allStrapiAsmetPagina {
          edges {
            node {
              strapiId
              slug
              titulo
              pagina_padre {
                id
                slug
                titulo
              }
            }
          }
        }
      }
    `
  )

  const allPages = data.allStrapiAsmetPagina.edges

  useEffect(() => {
    let flattenPages = []
    let pagesHierarchy = []
    allPages.forEach(el => {
      flattenPages = flattenPages.concat(Object.values(el))
    })

    const findPage = id => {
      return _.find(flattenPages, function (o) {
        return o.strapiId === id || o.slug === id
      })
    }

    var page = findPage(strapiId)

    pagesHierarchy = [page, ...pagesHierarchy]

    while (page.pagina_padre != null) {
      page = findPage(page.pagina_padre.id)
      pagesHierarchy = [page, ...pagesHierarchy]
    }

    setPages(pagesHierarchy)
  }, [strapiId, allPages])

  return pages
}

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

// Cookies
import CookieConsent from "react-cookie-consent"

const Cookies = props => {
  // const { content } = props

  return (
    <CookieConsent
      location="bottom"
      buttonText="Estoy de acuerdo"
      declineButtonText="No estoy de acuerdo"
      cookieName="gatsby-gdpr-google-analytics"
      disableStyles={true}
      containerClasses="asm-cookies-banner px-5 py-2 flex-column flex-md-row"
      contentClasses="cookies-content"
      buttonWrapperClasses="cookies-actions"
      buttonClasses="btn btn-danger py-0 py-md-2"
    >
      <p className="mb-2 mb-md-0">
        En el portal de Asmet Salud EPS S.A.S. utilizamos cookies y se puede
        recolectar datos personales para brindarte nuestros servicios en línea y
        mejorar tu experiencia como usuario. Haz click en el botón si estas de
        acuerdo con nuestras&nbsp;
        <Link
          to="/pagina/politica-seguridad-de-la-informacion-y-proteccion-de-datos"
          className="text-light text-600"
        >
          Políticas.
        </Link>
      </p>
    </CookieConsent>
  )
}

Cookies.propTypes = {
  content: PropTypes.string.isRequired,
}

Cookies.defaultProps = {
  content: ``,
}

export default Cookies

import React from "react"
import PropTypes from "prop-types"

// Components
import AccordionLists from "./accordion-lists"
import ContentToHTML from "../elements/content-html"

const AccordionListsContainer = props => {
  const { accordionListsTabs, tabId } = props
  return (
    <div className="asm-accordion-lists-container">
      <div className="scroller scroller-left">
        <i className="fa fa-chevron-left"></i>
      </div>
      <div className="scroller scroller-right">
        <i className="fa fa-chevron-right"></i>
      </div>
      <div className="nav-tabs-wrapper">
        <ul
          id={"multiAccordionTab" + tabId}
          className="nav nav-tabs nav-justified border-0"
        >
          {accordionListsTabs.map((item, index) => {
            return (
              <li
                className={
                  "nav-item " + (item.icono ? "nav-tab-icon" : "nav-tab-text")
                }
                role="presentation"
                key={index}
              >
                <a
                  className={"nav-link h-100 " + (index === 0 ? "active" : "")}
                  id={"multiAccordionTab" + tabId + index}
                  data-toggle="tab"
                  href={"#accordionTab" + tabId + index}
                  role="tab"
                  aria-controls={"accordionTab" + tabId + index}
                  aria-selected={index === 0 ? "true" : "false"}
                >
                  {item.icono ? (
                    <div className="tab-icon-container rounded-circle mx-auto p-2 my-2">
                      <img
                        src={item.icono.publicURL}
                        alt={`${item.titulo}-Icono`}
                        className="tab-icon mx-auto"
                      />
                    </div>
                  ) : null}
                  <p className="tab-header text-500 mb-0">{item.titulo}</p>
                </a>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="tab-content py-5" id={"multiAccordionTabContent" + tabId}>
        {accordionListsTabs.map((item, index) => {
          return (
            <div
              className={"tab-pane fade " + (index === 0 ? "show active" : "")}
              id={"accordionTab" + tabId + index}
              role="tabpanel"
              aria-labelledby={"multiAccordionTab" + tabId + index}
              key={index}
            >
              {item.descripcion ? (
                <ContentToHTML content={item.descripcion} className="mb-4" />
              ) : null}
              <AccordionLists
                accordionLists={[
                  ...item.listas_texto,
                  ...item.listas_documentacion,
                ]}
                tabId={parseInt("" + tabId + index)}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

AccordionListsContainer.propTypes = {
  tabId: PropTypes.number,
  accordionListsTabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      titulo: PropTypes.string.isRequired,
      icono: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
      }),
      descripcion: PropTypes.string,
      listas_texto: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          titulo: PropTypes.string.isRequired,
          contenido: PropTypes.string.isRequired,
        })
      ),
      listas_documentacion: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          titulo: PropTypes.string.isRequired,
          documentacion: PropTypes.arrayOf(
            PropTypes.shape({
              titulo: PropTypes.string.isRequired,
              archivo: PropTypes.shape({
                publicURL: PropTypes.string.isRequired,
                extension: PropTypes.string.isRequired,
              }),
              descripcion: PropTypes.string,
              es_descargable: PropTypes.bool,
              fecha_publicacion: PropTypes.string.isRequired,
            })
          ),
        })
      ),
    }).isRequired
  ).isRequired,
}

AccordionListsContainer.defaultProps = {
  tabId: 0,
  accordionListsTabs: [
    {
      id: "5fc67108b2b37d04310d0a30",
      titulo: `Tab #1`,
      icono: {
        publicURL: `http://placehold.it/200x200`,
      },
      descripcion: `<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>`,
      listas_documentacion: [
        {
          tipo_componente: "Lista-Documento",
          titulo: "Titulo lista 3 (Documento Preview)",
          documentacion: [
            {
              tipo_componente: "Documento",
              titulo: "Documento 1",
              fecha_publicacion: "2020-12-24T17:00:00.000Z",
              es_descargable: true,
              descripcion:
                "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
              archivo: {
                publicURL:
                  "/static/c7d354ff10c868752f1b032e5b0e55e8/1b1a6aed86944cc5ac76240d7cfce93f.pdf",
                extension: "pdf",
              },
            },
          ],
        },
        {
          tipo_componente: "Lista-Documento",
          titulo: "Titulo lista 4 (Documentos Listado)",
          documentacion: [
            {
              tipo_componente: "Documento",
              titulo: "Documento 1",
              fecha_publicacion: "2020-12-24T17:00:00.000Z",
              es_descargable: true,
              descripcion:
                "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
              archivo: {
                publicURL:
                  "/static/c7d354ff10c868752f1b032e5b0e55e8/1b1a6aed86944cc5ac76240d7cfce93f.pdf",
                extension: "pdf",
              },
            },
            {
              tipo_componente: "Documento",
              titulo: "Documento 2",
              fecha_publicacion: "2020-12-24T17:00:00.000Z",
              es_descargable: true,
              descripcion:
                "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
              archivo: {
                publicURL:
                  "/static/c7d354ff10c868752f1b032e5b0e55e8/1b1a6aed86944cc5ac76240d7cfce93f.pdf",
                extension: "pdf",
              },
            },
            {
              tipo_componente: "Documento",
              titulo: "Documento 3",
              fecha_publicacion: "2020-12-24T17:00:00.000Z",
              es_descargable: true,
              descripcion:
                "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
              archivo: {
                publicURL:
                  "/static/c7d354ff10c868752f1b032e5b0e55e8/1b1a6aed86944cc5ac76240d7cfce93f.pdf",
                extension: "pdf",
              },
            },
          ],
        },
      ],
      listas_texto: [
        {
          tipo_componente: "Lista-Texto",
          titulo: "Titulo lista 1",
          contenido:
            "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae totam dolorem, nobis natus esse omnis odio culpa ad aperiam molestiae incidunt at enim consequatur porro ab asperiores itaque praesentium qui! Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae totam dolorem, nobis natus esse omnis odio culpa ad aperiam molestiae incidunt at enim consequatur porro ab asperiores itaque praesentium qui!</p>",
        },
        {
          tipo_componente: "Lista-Texto",
          titulo: "Titulo lista 2",
          contenido:
            "<h2>Subtitulo</h2><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae totam dolorem, nobis natus esse omnis odio culpa ad aperiam molestiae incidunt at enim consequatur porro ab asperiores itaque praesentium qui! Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae totam dolorem, nobis natus esse omnis odio culpa ad aperiam molestiae incidunt at enim consequatur porro ab asperiores itaque praesentium qui!</p><h3>Subtitulo</h3><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae totam dolorem, nobis natus esse omnis odio culpa ad aperiam molestiae incidunt at enim consequatur porro ab asperiores itaque praesentium qui! Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae totam dolorem, nobis natus esse omnis odio culpa ad aperiam molestiae incidunt at enim consequatur porro ab asperiores itaque praesentium qui!</p>",
        },
      ],
    },
    {
      id: "5fc67108b2b37d04310d0a31",
      titulo: `Tab #2`,
      icono: {
        publicURL: `http://placehold.it/200x200`,
      },
      listas_documentacion: [],
      listas_texto: [
        {
          tipo_componente: "Lista-Texto",
          titulo: "Titulo lista 1",
          contenido:
            "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae totam dolorem, nobis natus esse omnis odio culpa ad aperiam molestiae incidunt at enim consequatur porro ab asperiores itaque praesentium qui! Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae totam dolorem, nobis natus esse omnis odio culpa ad aperiam molestiae incidunt at enim consequatur porro ab asperiores itaque praesentium qui!</p>",
        },
      ],
    },
  ],
}

export default AccordionListsContainer

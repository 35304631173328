import React from "react"
import PropTypes from "prop-types"
import Moment from "react-moment"

// Components
import AudioSingle from "./audio-single"

const ComponentAudio = ({ mediaAudios }) => {
  return (
    <div className="asm-media-main">
      {mediaAudios.length === 1 ? (
        <div className="asm-media-single">
          <AudioSingle singleAudio={mediaAudios[0]} />
        </div>
      ) : (
        <div className="asm-media-grid">
          <div className="row row-cols-1 row-cols-md-3">
            {mediaAudios.map((audio, index) => {
              return (
                <div className="col mb-5" key={index}>
                  <div className="card h-100 rounded-0 border-0">
                    <audio
                      controls
                      className="w-100"
                      src={audio.archivo.publicURL}
                    >
                      <track default kind="captions" srcLang="en" />
                      Su navegador no soporta elementos de
                      <code>audio</code>.
                    </audio>
                    <div className="card-body">
                      {audio.tipo && (
                        <p className="card-subtitle">
                          <i className="fa fa-headphones mr-1"></i>
                          {audio.tipo}
                        </p>
                      )}
                      <h5 className="card-title">{audio.titulo}</h5>
                      <p className="card-text mb-0">
                        Publicado el&nbsp;
                        <Moment format="LL">{audio.publicado_en}</Moment>
                      </p>
                      <a
                        href={`/audios/${audio.slug}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="stretched-link card-text"
                      >
                        Ver más
                      </a>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

ComponentAudio.propTypes = {
  mediaAudios: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      titulo: PropTypes.string.isRequired,
      archivo: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
      }),
      descripcion: PropTypes.string.isRequired,
      publicado_en: PropTypes.string.isRequired,
      tipo: PropTypes.string,
      blog_subcategorias: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string.isRequired,
          nombre: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
}

ComponentAudio.defaultProps = {
  mediaAudios: [
    {
      id: `5ff3ac07ce1b5e19cb07092b`,
      slug: `cambio-de-regimen`,
      titulo: `Cambio de régimen`,
      archivo: {
        publicURL: `/static/0c481e87f2774b1bd41a0a70d9b70d11/4d1eaa21992d880810d7f98e7ceb959d.mp3`,
      },
      publicado_en: `2020-12-17T17:00:00.000Z`,
      descripcion: `Acompáñanos para hablar sobre #DiabetesGestacional. Si estás en #embarazo 🤰🏻o conoces a alguien que le pueda interesar esta información ¡Comparte! #Live #EnfermedadesDelEmbarazo #Gestación #MaternoPerinatal`,
      tipo: `Entrevista`,
      blog_subcategorias: [
        {
          slug: `vih`,
          nombre: `VIH`,
        },
      ],
    },
  ],
}

export default ComponentAudio

import React from "react"
import PropTypes from "prop-types"
import Moment from "react-moment"
import { Link } from "gatsby"

const VideoSidebar = ({ singleVideoID, sidebarVideos, handleSingleVideo }) => {
  return sidebarVideos.map((video, index) => {
    return (
      <div
        className={
          "card rounded-0 border-0 mb-3" +
          (singleVideoID === (video.strapiId || video.id) ? " d-none" : "")
        }
        key={index}
      >
        <div className="row no-gutters position-relative">
          <div className="col-5">
            <div className="card-img-container">
              <img
                // Fetch thumbnail of youtube video based on: https://yt-thumb.canbeuseful.com/en
                src={
                  "http://img.youtube.com/vi/" + video.link + "/mqdefault.jpg"
                }
                className="card-img rounded-0"
                alt={video.titulo}
              />
            </div>
          </div>
          <div className="col-7 position-static">
            <div className="card-body px-3 py-0">
              <h6 className="card-title mb-2">{video.titulo}</h6>
              <small className="card-text text-muted d-block">
                Publicado el&nbsp;
                <Moment format="LL">{video.publicado_en}</Moment>
              </small>
            </div>
            {handleSingleVideo ? (
              <button
                aria-label="Toggle video"
                className="btn btn-link stretched-link"
                onClick={() => handleSingleVideo(video)}
              ></button>
            ) : (
              <Link to={`/videos/${video.slug}`} className="stretched-link">
                {""}
              </Link>
            )}
          </div>
        </div>
      </div>
    )
  })
}

VideoSidebar.propTypes = {
  singleVideoID: PropTypes.string.isRequired,
  sidebarVideos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      strapiId: PropTypes.string,
      slug: PropTypes.string.isRequired,
      titulo: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      descripcion: PropTypes.string.isRequired,
      publicado_en: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleSingleVideo: PropTypes.func,
}

VideoSidebar.defaultProps = {
  singleVideoID: `1`,
  sidebarVideos: [
    {
      id: `5ff3ac07ce1b5e19cb07092b`,
      strapiId: `5ff3ac07ce1b5e19cb07092b`,
      titulo: `Diabetes Gestacional`,
      slug: `diabetes-gestacional`,
      link: `4r0IqcCBBVY`,
      descripcion: `Acompáñanos para hablar sobre #DiabetesGestacional. Si estás en #embarazo 🤰🏻o conoces a alguien que le pueda interesar esta información ¡Comparte! #Live #EnfermedadesDelEmbarazo #Gestación #MaternoPerinatal`,
      publicado_en: `2020-12-17T17:00:00.000Z`,
    },
  ],
  handleSingleVideo: null,
}

export default VideoSidebar

import React from "react"
import PropTypes from "prop-types"

// Components
import CustomLink from "./custom-link"
import Breadcrumb from "./breadcrumb"

// Hooks
import { usePageHierarchy } from "../../hooks/use-page-hierarchy"

const PageMenu = ({ menu, pageId }) => {
  const pageHierarchy = usePageHierarchy(pageId)
  
  const crumbs = []
  pageHierarchy.forEach(el => {
    crumbs.push({ path: `/pagina/${el.slug}`, label: el.titulo })
  })

  return (
    <div className="asm-menu-single row">
      <div className="col-12 col-sm-4 main-container bg-black-alpha text-white-alpha d-flex flex-column pr-5 py-3">
        <Breadcrumb crumbs={crumbs} />
        <div className="card bg-black-alpha border-0 rounded-0 my-auto">
          <div className="card-body p-0">
            <h3 className="card-title text-600 mb-4">{menu.titulo}</h3>
            {menu.subtitulo ? (
              <h6 className="card-subtitle mb-3 text-blue-beta">
                {menu.subtitulo}
              </h6>
            ) : null}
            {menu.descripcion ? (
              <p className="card-text">{menu.descripcion}</p>
            ) : null}
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-4 links-container bg-gray-alpha d-flex align-items-center px-3 px-sm-5">
        <ul>
          {menu.links.map((item, index) => {
            return (
              <li
                key={index}
                className={"pl-3" + (pageId === item.link_id ? " active" : "")}
              >
                <CustomLink link={item}>{item.nombre}</CustomLink>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="col-12 col-sm-4 img-container bg-gray-beta p-0">
        <img
          src={menu.imagen && menu.imagen.publicURL ? menu.imagen.publicURL : "http://placehold.it/250x250"}
          alt={menu.titulo}
          style={
            menu.links.length > 4
              ? { maxHeight: `${menu.links.length * 60}px` }
              : {}
          }
        />
      </div>
    </div>
  )
}

PageMenu.propTypes = {
  menu: PropTypes.shape({
    id: PropTypes.string,
    titulo: PropTypes.string.isRequired,
    subtitulo: PropTypes.string,
    descripcion: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        nombre: PropTypes.string.isRequired,
        tipo: PropTypes.string.isRequired,
        link_id: PropTypes.string.isRequired,
        icono: PropTypes.shape({
          publicURL: PropTypes.string.isRequired,
        }),
      })
    ).isRequired,
    imagen: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }).isRequired,
  }),
  pageId: PropTypes.string.isRequired,
}

PageMenu.defaultProps = {
  menu: {
    id: `5fc67108b2b37d04310d0a33`,
    titulo: `Titulo Menú`,
    subtitulo: `Subtitulo Menú`,
    descripcion: `Lorem, ipsum dolor sit amet consectetur adipisicing elit.`,
    links: [
      {
        nombre: `Link`,
        tipo: `Pagina`,
        link_id: `5fe4770368cc14220204cae4`,
      },
    ],
    imagen: {
      publicURL: `/static/64a1c492a90e23e4aff745cc9debb823/2ff1567298e29c83ec169c2ad2f93ddf.png`,
    },
  },
}

export default PageMenu

import React from "react"
import PropTypes from "prop-types"

const logoGov = require("../../assets/images/logos/gov-isologo.png")

const GovBar = props => {
  const { alt } = props
  return (
    <div id="asm-govbar">
      <div className="container py-2 px-4">
        <a href="https://www.gov.co/" target="_blank" rel="noopener noreferrer">
          <img src={logoGov} alt={alt} loading="lazy" />
        </a>
      </div>
    </div>
  )
}

GovBar.propTypes = {
  alt: PropTypes.string,
}

GovBar.defaultProps = {
  alt: "Gov Logo",
}

export default GovBar

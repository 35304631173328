import { useEffect, useState } from "react"

export const useGroupedComponents = (allComponents) => {
  
  const [groupedComponents, setGroupedComponents] = useState([])

  useEffect(() => {
    const regexList = [/Lista-/g, /Pestaña-/g]

    const getGroups = (components, re) => {
      let result = {
        group: [],
        rest: [],
      }

      try {
        components.forEach((item, index) => {
          if (item.tipo_componente.match(re)) {
            result.group.push(item)
          } else {
            result.rest = components.slice(index)
            throw new Error()
          }
        })
      } catch (error) {}

      return result
    }

    const groupComponents = (components, result) => {
      if (components.length === 0) {
        return result
      }

      let head = components[0]
      let rest = []
      if (head.tipo_componente.match(regexList[0])) {
        let groupResult = getGroups(components, regexList[0])
        rest = groupResult.rest
        result = [
          ...result,
          {
            type: "Accordion",
            data: groupResult.group,
          },
        ]
      } else if (head.tipo_componente.match(regexList[1])) {
        let groupResult = getGroups(components, regexList[1])
        rest = groupResult.rest
        result = [
          ...result,
          {
            type: "Tab",
            data: groupResult.group,
          },
        ]
      } else {
        result = [
          ...result,
          {
            type: head.tipo_componente,
            data: head,
          },
        ]
        rest = components.slice(1)
      }
      return groupComponents(rest, result)
    }

    setGroupedComponents(
      groupComponents([...allComponents], [])
    )
  }, [allComponents])

  return groupedComponents
}

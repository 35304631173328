import React from "react"
import PropTypes from "prop-types"
import parse, { domToReact } from "html-react-parser"

const ContentToHTML = props => {
  const { content, className } = props

  return (
    <div className={"asm-ckeditor-html " + className}>
      {parse(content, parseOptions)}
    </div>
  )
}

const parseOptions = {
  replace: function (domNode) {
    if (domNode.parent === null && domNode.type === "text")
      return <p>{domNode.data}</p>

    if (!domNode.name) return

    if (domNode.name === "a") {
      return RenderLink(domNode)
    } else if (domNode.name === "img") {
      return RenderImg(domNode)
    } else if (domNode.name === "table") {
      return RenderTable(domNode)
    } else if (domNode.name === "blockquote") {
      return RenderBlockQuote(domNode)
    }
  },
}
function RenderLink(domNode) {
  return (
    <a href={domNode.attribs.href} target="_blank" rel="noopener noreferrer">
      {domToReact(domNode.children, parseOptions)}
    </a>
  )
}
function RenderImg(domNode) {
  return (
    <img
      src={
        domNode.attribs.src.startsWith("http")
          ? domNode.attribs.src
          : `${process.env.API_URL}${domNode.attribs.src}`
      }
      alt={`Imágen: ${domNode.attribs.alt}`}
    />
  )
}
function RenderTable(domNode) {
  return (
    <div className="table-responsive">
      <table className="table table-hover">
        {domToReact(domNode.children, parseOptions)}
      </table>
    </div>
  )
}
function RenderBlockQuote(domNode) {
  return (
    <blockquote className="alt-blockquote">
      {domToReact(domNode.children, parseOptions)}
    </blockquote>
  )
}

ContentToHTML.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string.isRequired,
}

ContentToHTML.defaultProps = {
  className: ``,
  content: `<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>`,
}

export default ContentToHTML

import React from "react"
import PropTypes from "prop-types"
import Moment from "react-moment"
import { Link } from "gatsby"

// Components
import ContentToHTML from "./content-html"

const VideoSingle = ({ singleVideo }) => {
  return (
    <div className="card rounded-0 border-0 mb-5 mb-sm-0">
      <div className="embed-responsive embed-responsive-16by9">
        <iframe
          className="embed-responsive-item"
          src={"https://www.youtube.com/embed/" + singleVideo.link + "?rel=0"}
          title={singleVideo.titulo}
          allowFullScreen
        ></iframe>
      </div>
      <div className="card-body px-0 px-sm-4">
        {singleVideo.blog_subcategorias && singleVideo.blog_subcategorias.length > 0 && (
          <div className="card-tags mb-3">
            {singleVideo.blog_subcategorias.map((c, i) => (
              <Link
                key={i}
                to={`/videos?t=Todos&c=${c.nombre}`}
                className="badge badge-info mr-1"
              >
                {c.nombre}
              </Link>
            ))}
          </div>
        )}
        <h2 className="mb-3 text-600">{singleVideo.titulo}</h2>
        {singleVideo.tipo && (
          <Link
            to={`/videos?t=${singleVideo.tipo}&c=Todos`}
            className="d-block mb-2"
          >
            <i className="fa fa-video-camera mr-1"></i>
            {singleVideo.tipo}
          </Link>
        )}
        <small className="card-subtitle d-block">
          Publicado el&nbsp;
          <Moment format="LL">{singleVideo.publicado_en}</Moment>
        </small>
        <a
          href={`https://youtu.be/${singleVideo.link}`}
          className="btn btn-link p-0 mb-4"
          target="_blank"
          rel="noopener noreferrer"
          role="button"
        >
          Ver en Youtube
        </a>
        <div className="card-text">
          <ContentToHTML
            content={
              singleVideo.descripcion
                ? singleVideo.descripcion
                : "No hay descripción!"
            }
          />
        </div>
      </div>
    </div>
  )
}

VideoSingle.propTypes = {
  singleVideo: PropTypes.shape({
    titulo: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    descripcion: PropTypes.string.isRequired,
    publicado_en: PropTypes.string.isRequired,
    tipo: PropTypes.string,
    blog_subcategorias: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string.isRequired,
        nombre: PropTypes.string.isRequired,
      })
    ),
  }),
}

VideoSingle.defaultProps = {
  singleVideo: {
    titulo: `Diabetes Gestacional`,
    link: `4r0IqcCBBVY`,
    descripcion: `Acompáñanos para hablar sobre #DiabetesGestacional. Si estás en #embarazo 🤰🏻o conoces a alguien que le pueda interesar esta información ¡Comparte! #Live #EnfermedadesDelEmbarazo #Gestación #MaternoPerinatal`,
    publicado_en: `2020-12-17T17:00:00.000Z`,
    tipo: `Otro`,
    blog_subcategorias: [
      {
        slug: `vih`,
        nombre: `VIH`,
      },
    ],
  },
}

export default VideoSingle

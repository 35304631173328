import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

// Components
import CustomLink from "../elements/custom-link"

// Images Instituciones
const logoAsmetSaludPrimary = require("../../assets/images/logos/asmet-color_black@2x.png")
const logoAsmetSaludSecondary = require("../../assets/images/logos/asmet-white_white@2x.png")
const logoGooglePlay = require("../../assets/images/logos/google-play-logo.png")
const logoOFicinaVirtualColor = require("../../assets/images/logos/oficina-virtual-color.png")
const logoOficinaVirtualBlanco = require("../../assets/images/logos/oficina-virtual-logo.png")
// Images Links
const iconPeople = require("../../assets/images/icons/afiliados.png")
// Images Settings
const logoSearch = require("../../assets/images/icons/tools/search-gray.png")
const logoBoxes = require("../../assets/images/icons/settings/boxes-green.png")
const iconContact01 = require("../../assets/images/icons/contact/contact-01.png")
const iconContact02 = require("../../assets/images/icons/contact/contact-02.png")

const Navbar = props => {
  const { menuPrincipal, socialNetworks, primaryLinks, contacto } = props
  return (
    <div id="asm-navbar">
      {/* --------------------------------
       Default Navbar for Desktop devices
       ----------------------------------- */}
      <nav
        id="navbar-default"
        className="navbar navbar-expand-lg navbar-light py-0 mt-n3 d-none d-lg-block"
      >
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <Link className="navbar-brand p-0 mb-2" to="/">
              <img src={logoAsmetSaludPrimary} alt="Logo Asmet Salud" loading="lazy" />
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarDefault"
              aria-controls="navbarDefault"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarDefault">
              <ul className="navbar-nav ml-auto">
                {menuPrincipal.map((item, index) => {
                  return item.titulo.tipo === "Grupo" ? (
                    <li className="nav-item mr-4 dropdown" key={index}>
                      <Link
                        to="/"
                        className="nav-link dropdown-toggle"
                        id={item.titulo.nombre + "Dropdown"}
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {item.titulo.nombre}
                      </Link>
                      <div
                        className={"dropdown-menu dropdown-" + item.titulo.nombre}
                        aria-labelledby={item.titulo.nombre + "Dropdown"}
                      >
                        {item.links ? (
                          item.links.map((subitem, subIndex) => {
                            return (
                              <CustomLink
                                link={subitem}
                                customClass="dropdown-item"
                                key={subIndex}
                              >
                                {subitem.nombre}
                              </CustomLink>
                            )
                          })
                        ) : (
                          <Link className="dropdown-item" to="/">
                            No hay links
                          </Link>
                        )}
                      </div>
                    </li>
                  ) : (
                    <li className="nav-item mr-4" key={index}>
                      <CustomLink link={item.titulo} customClass="nav-link">
                        {item.titulo.nombre}
                      </CustomLink>
                    </li>
                  )
                })}

                <li className="nav-item dropdown">
                  <Link
                    to="/"
                    className="nav-link dropdown-toggle"
                    id="contactanosDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Contáctanos
                  </Link>
                  <div
                    className="dropdown-menu dropdown-menu-right dropdown-contacto"
                    aria-labelledby="contactanosDropdown"
                  >
                    <div className="d-flex">
                      <div className="px-5">
                        <h4 className="dropdown-heading pb-1 mb-3">
                          Nuestras Líneas
                        </h4>
                        <a
                          className="dropdown-item pt-0"
                          href={`tel:${contacto.num_fijo}`}
                        >
                          <span className="text-700">{contacto.num_fijo}</span>
                        </a>
                        <a
                          className="dropdown-item pt-0"
                          href={`tel:${contacto.num_celular}`}
                        >
                          <span className="text-700">{contacto.num_celular}</span>
                        </a>
                        <CustomLink
                          link={contacto.enlaces_contacto[0]}
                          customClass="dropdown-item btn d-flex align-items-center shadow-sm py-1 pr-2"
                          roleButton={true}
                        >
                          <img
                            src={contacto.enlaces_contacto[0].icono.publicURL}
                            alt={contacto.enlaces_contacto[0].nombre}
                            className="mr-2"
                          />
                          {contacto.enlaces_contacto[0].nombre}
                        </CustomLink>
                        <CustomLink
                          link={contacto.enlaces_contacto[1]}
                          customClass="dropdown-item btn d-flex align-items-center shadow-sm py-1 pr-2 mt-3"
                          roleButton={true}
                        >
                          <img
                            src={contacto.enlaces_contacto[1].icono.publicURL}
                            alt={contacto.enlaces_contacto[1].nombre}
                            className="mr-2"
                          />
                          {contacto.enlaces_contacto[1].nombre}
                        </CustomLink>
                      </div>
                      <div className="px-5">
                        <h4 className="dropdown-heading pb-1 mb-3">
                          Sede Nacional
                        </h4>
                        <p className="mb-0">{contacto.sede_nacional}</p>
                        <CustomLink
                          link={contacto.enlaces_contacto[2]}
                          customClass="dropdown-item"
                        >
                          Asistente Virtual
                        </CustomLink>
                        <CustomLink
                          link={contacto.enlaces_contacto[2]}
                          customClass="dropdown-item py-0"
                        >
                          <img
                            className="w-100"
                            src={contacto.enlaces_contacto[2]?.icono?.publicURL}
                            alt={contacto.enlaces_contacto[2]?.nombre}
                          />
                        </CustomLink>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      {/* ------------------------------
      Navbar menu for Small Devices (XS) 
      ---------------------------------- */}

      <nav
        id="navbar-mobile"
        className="navbar navbar-expand-lg navbar-light bg-white-alpha d-flex d-lg-none"
      >
        <Link className="navbar-brand" to="/">
          <img src={logoAsmetSaludPrimary} alt="Logo Asmet Salud" loading="lazy" />
        </Link>

        <div className="nav-item ml-auto">
          <Link className="nav-link nav-search mr-2 px-1" to="/search">
            <img src={logoSearch} alt="Logo Búsqueda" />
          </Link>
        </div>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="nav-item w-100 border-top pt-2 text-center">
          <CustomLink
            link={primaryLinks[0]}
            customClass="nav-link of-virtual btn btn-asm-red-alpha rounded-pill d-inline-block px-4 py-1"
            roleButton={true}
          >
            <img
              src={primaryLinks[0].icono && primaryLinks[0].icono.publicURL ? primaryLinks[0].icono.publicURL : logoOficinaVirtualBlanco}
              alt={primaryLinks[0].nombre}
            />
            <i className="fa fa-angle-right ml-2"></i>
          </CustomLink>
        </div>

        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item py-3">
              <ul className="d-flex p-0">
                <Link className="nav-link nav-lang mr-2" to="/">
                  Inglés
                </Link>
                <Link className="nav-link nav-lang mr-4 active" to="/">
                  Español
                </Link>
                <Link className="nav-link nav-lang mr-2" to="/sitemap">
                  <img src={logoBoxes} alt="Logo Boxes" />
                </Link>
                <Link
                  className="nav-link nav-lang navbar-toggler border-0 mr-2 ml-auto"
                  to="/"
                  role="button"
                  data-toggle="collapse"
                  data-target="#navbarResponsive"
                  aria-controls="navbarResponsive"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="fa fa-times fa-lg"></i>
                </Link>
              </ul>
            </li>

            <li className="nav-item py-3">
              <Link className="nav-link navbar-brand text-left" to="/">
                <img src={logoAsmetSaludSecondary} alt="Logo Asmet Salud" />
              </Link>
            </li>

            <li className="nav-item py-2">
              <ul className="d-flex p-0">
                {primaryLinks.map((item, index) => {
                  return (
                    item.nombre !== "Oficina Virtual" && (
                      <CustomLink
                        link={item}
                        customClass="nav-link nav-icons"
                        key={index}
                      >
                        <img
                          src={item.icono.publicURL}
                          alt={item.nombre}
                          className="mb-1"
                        />
                        {item.nombre}
                      </CustomLink>
                    )
                  )
                })}
              </ul>
            </li>

            <li className="nav-item py-3">
              <CustomLink
                link={primaryLinks[0]}
                customClass="nav-link of-virtual text-center"
              >
                <img
                  src={primaryLinks[0].icono.publicURL}
                  alt={primaryLinks[0].nombre}
                />
              </CustomLink>
            </li>

            {menuPrincipal.map((item, index) => {
              return item.titulo.tipo === "Grupo" ? (
                <li className="nav-item py-2 dropdown" key={index}>
                  <Link
                    to="/"
                    className="nav-link dropdown-toggle"
                    id={item.titulo.nombre + "Dropdown"}
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {item.titulo.nombre}
                  </Link>
                  <div
                    className={
                      "dropdown-menu dropdown-" +
                      item.titulo.nombre +
                      " bg-gray-alpha border-0 rounded-0 mx-n3"
                    }
                    aria-labelledby={item.titulo.nombre + "Dropdown"}
                  >
                    {item.links ? (
                      item.links.map((subItem, subIndex) => {
                        return (
                          <CustomLink
                            link={subItem}
                            customClass="dropdown-item my-3"
                            key={subIndex}
                          >
                            {subItem.nombre}
                          </CustomLink>
                        )
                      })
                    ) : (
                      <Link className="dropdown-item my-3" to="/">
                        No hay links
                      </Link>
                    )}
                  </div>
                </li>
              ) : (
                <li className="nav-item py-2" key={index}>
                  <CustomLink link={item.titulo} customClass="nav-link">
                    {item.titulo.nombre}
                  </CustomLink>
                </li>
              )
            })}

            <li className="nav-item py-2 dropdown">
              <Link
                to="/"
                className="nav-link dropdown-toggle"
                id="contactanosDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Contáctanos
              </Link>
              <div
                className="dropdown-menu dropdown-contacto bg-gray-alpha border-0 rounded-0 mx-n3"
                aria-labelledby="contactanosDropdown"
              >
                <h5 className="dropdown-header text-dark text-600">Llámanos</h5>
                <a
                  className="dropdown-item my-1"
                  href={`tel:${contacto.num_fijo}`}
                >
                  <span className="text-900 d-block">{contacto.num_fijo}</span>
                </a>
                <a
                  className="dropdown-item my-1"
                  href={`tel:${contacto.num_celular}`}
                >
                  <span className="text-900 d-block">
                    {contacto.num_celular}
                  </span>
                </a>
                <div className="dropdown-divider"></div>
                <h5 className="dropdown-header text-dark text-600">
                  Sede Nacional
                </h5>
                <div className="dropdown-item my-1" to="/">
                  {contacto.sede_nacional}
                </div>
                <div className="dropdown-divider"></div>
                <div className="dropdown-item mt-4">
                  <CustomLink
                    link={contacto.enlaces_contacto[0]}
                    customClass="btn btn-primary d-flex align-items-center shadow-sm py-1 pr-2"
                    roleButton={true}
                  >
                    <img
                      src={contacto.enlaces_contacto[0].icono.publicURL}
                      alt={contacto.enlaces_contacto[0].nombre}
                      className="mr-2"
                    />
                    {contacto.enlaces_contacto[0].nombre}
                  </CustomLink>
                </div>
                <div className="dropdown-item mt-1">
                  <CustomLink
                    link={contacto.enlaces_contacto[1]}
                    customClass="btn btn-primary d-flex align-items-center shadow-sm py-1 pr-2"
                    roleButton={true}
                  >
                    <img
                      src={contacto.enlaces_contacto[1].icono.publicURL}
                      alt={contacto.enlaces_contacto[1].nombre}
                      className="mr-2"
                    />
                    {contacto.enlaces_contacto[1].nombre}
                  </CustomLink>
                </div>
                <div className="dropdown-divider"></div>
                <h5 className="dropdown-header text-dark text-600">
                  Asistente Virtual
                </h5>
                <div className="dropdown-item mt-1">
                  <CustomLink
                    link={contacto.enlaces_contacto[2]}
                    customClass="d-block w-75 mx-auto"
                  >
                    <img
                      className="w-100"
                      src={contacto.enlaces_contacto[2]?.icono?.publicURL}
                      alt={contacto.enlaces_contacto[2]?.nombre}
                    />
                  </CustomLink>
                </div>
              </div>
            </li>

            <li className="nav-item my-5">
              <ul className="d-flex w-75 p-0 m-auto">
                {socialNetworks.map((item, index) => {
                  return (
                    <CustomLink
                      link={item}
                      customClass="nav-link nav-icons"
                      key={index}
                    >
                      <img src={item.icono && item.icono.publicURL ? item.icono.publicURL : "http://placehold.it/75x75"} alt={item.nombre} />
                    </CustomLink>
                  )
                })}
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  )
}

Navbar.propTypes = {
  menuPrincipal: PropTypes.arrayOf(
    PropTypes.shape({
      titulo: PropTypes.shape({
        nombre: PropTypes.string.isRequired,
        tipo: PropTypes.string.isRequired,
        link_id: PropTypes.string.isRequired,
      }).isRequired,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          nombre: PropTypes.string.isRequired,
          tipo: PropTypes.string.isRequired,
          link_id: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  socialNetworks: PropTypes.arrayOf(
    PropTypes.shape({
      nombre: PropTypes.string.isRequired,
      tipo: PropTypes.string.isRequired,
      link_id: PropTypes.string.isRequired,
      icono: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  primaryLinks: PropTypes.arrayOf(
    PropTypes.shape({
      nombre: PropTypes.string.isRequired,
      tipo: PropTypes.string.isRequired,
      link_id: PropTypes.string.isRequired,
      icono: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  contacto: PropTypes.shape({
    num_fijo: PropTypes.string.isRequired,
    num_celular: PropTypes.string.isRequired,
    sede_nacional: PropTypes.string.isRequired,
    enlaces_contacto: PropTypes.arrayOf(
      PropTypes.shape({
        nombre: PropTypes.string.isRequired,
        tipo: PropTypes.string.isRequired,
        link_id: PropTypes.string.isRequired,
        icono: PropTypes.shape({
          publicURL: PropTypes.string.isRequired,
        }).isRequired,
      })
    ).isRequired,
  }),
}

Navbar.defaultProps = {
  menuPrincipal: [
    {
      titulo: {
        nombre: "Conócenos",
        tipo: "Grupo",
        link_id: "/",
      },
      links: [
        {
          nombre: "Quienes Somos?",
          tipo: "Pagina",
          link_id: "/",
        },
        {
          nombre: "Actividades y Resultados en Salud",
          tipo: "Pagina",
          link_id: "/",
        },
        {
          nombre: "Información Financiera y Administrativa",
          tipo: "Pagina",
          link_id: "/",
        },
        {
          nombre: "Riesgos Corporativos",
          tipo: "Pagina",
          link_id: "/",
        },
      ],
    },
    {
      titulo: {
        nombre: "Gobierno Organizacional",
        tipo: "Pagina",
        link_id: "/",
      },
      links: [],
    },
    {
      titulo: {
        nombre: "Trabaja con Nosotros",
        tipo: "Pagina",
        link_id: "/",
      },
      links: [],
    },
  ],
  socialNetworks: [
    {
      nombre: "Twitter",
      tipo: "Externo",
      link_id: "/",
      icono: {
        publicURL:
          "/static/70a607edb2a909936b47d4dd978a4209/ee484b0c99ec904f199eef06b18a61ce.png",
      },
    },
  ],
  primaryLinks: [
    {
      nombre: "Oficina Virtual",
      tipo: "Pagina",
      link_id: "/",
      icono: {
        publicURL: logoOficinaVirtualBlanco,
      },
    },
    {
      nombre: "Afiliados",
      tipo: "Pagina",
      link_id: "/",
      icono: {
        publicURL: iconPeople,
      },
    },
    {
      nombre: "Prestadores",
      tipo: "Pagina",
      link_id: "/",
      icono: {
        publicURL: iconPeople,
      },
    },
    {
      nombre: "Empleadores",
      tipo: "Pagina",
      link_id: "/",
      icono: {
        publicURL: iconPeople,
      },
    },
  ],
  contacto: {
    num_fijo: "01 8000 91 38 76",
    num_celular: "0328 35 37 83",
    sede_nacional: "Cra. 4 No. 18N-46 (57) (2) 8312000 Popayán - Colombia",
    enlaces_contacto: [
      {
        nombre: "Extensiones Telefónicas",
        tipo: "Pagina",
        link_id: "/",
        icono: {
          publicURL: iconContact01,
        },
      },
      {
        nombre: "Horarios de Atención",
        tipo: "Pagina",
        link_id: "/",
        icono: {
          publicURL: iconContact02,
        },
      },
      {
        nombre: "Oficina Virtual",
        tipo: "Pagina",
        link_id: "/",
        icono: {
          publicURL: logoOFicinaVirtualColor,
        },
      },
      {
        nombre: "Nuestra App",
        tipo: "Pagina",
        link_id: "/",
        icono: {
          publicURL: logoGooglePlay,
        },
      },
    ],
  },
}

export default Navbar

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Moment from "react-moment"

const PhotoGrid = ({ allPhotos }) => {
  return (
    <div
      className={
        "asm-media-grid row row-cols-1 row-cols-md-" +
        (allPhotos.length < 4 ? allPhotos.length : "3")
      }
    >
      {allPhotos.map((photo, index) => {
        return (
          <div className="col mb-5" key={index}>
            <div className="card h-100 rounded-0 border-0">
              <img
                alt={photo.titulo}
                src={photo.imagenes[0].url}
                className="card-img-top"
              />
              <div className="card-body">
                <p className="card-title">
                  {photo.titulo}
                  <i className="fa fa-share float-right ml-1"></i>
                </p>
                <p className="card-text mb-0">
                  Publicado el&nbsp;
                  <Moment format="LL">{photo.fecha}</Moment>
                </p>
                <Link to={`/fotos/${photo.slug}`} className="stretched-link">
                  {""}
                </Link>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

PhotoGrid.propTypes = {
  allPhotos: PropTypes.arrayOf(
    PropTypes.shape({
      strapiId: PropTypes.string,
      titulo: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      fecha: PropTypes.string.isRequired,
      imagenes: PropTypes.arrayOf(
        PropTypes.shape({
          formats: PropTypes.object,
          url: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
}

PhotoGrid.defaultProps = {
  allPhotos: [
    {
      strapiId: `5ff3ac07ce1b5e19cb07092b`,
      titulo: `Jornada de vacunación`,
      slug: `jornada-de-vacunacion`,
      fecha: `2020-12-17T17:00:00.000Z`,
      imagenes: [
        {
          formats: {
            small: {
              publicURL: `/static/2d447e708a79cd7427f1fcce74c5bb4f/f77014d8352e6bc422177f80bf6b9921.png`,
            },
          },
          url: `https://asmed-staging.s3.amazonaws.com/jornada_vacunacion_4_3cb0ad5dc6.png`,
        },
      ],
    },
  ],
}

export default PhotoGrid

import React from "react"
import PropTypes from "prop-types"

// Components
import CustomLink from "./custom-link"

const ItemSingle = ({ item }) => {
  return (
    <div className="card asm-item-single h-100">
      <img
        alt={item.titulo}
        src={item.imagen?.publicURL}
        className="card-img-top"
      />
      <div className="card-body">
        <h5 className="card-title text-blue-alpha">{item.titulo}</h5>
        <p className="card-text">{item.descripcion}</p>
      </div>
      {item.link && (
        <div className="card-footer bg-white-alpha border-0 p-0 text-center">
          <CustomLink
            link={item.link}
            customClass="btn btn-asm-gray-alpha text-blue-alpha rounded-pill shadow-sm mb-3"
            stretchedLink={true}
          >
            {item.link.nombre}
          </CustomLink>
        </div>
      )}
    </div>
  )
}

ItemSingle.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    titulo: PropTypes.string.isRequired,
    descripcion: PropTypes.string.isRequired,
    imagen: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }).isRequired,
    link: PropTypes.shape({
      nombre: PropTypes.string.isRequired,
      tipo: PropTypes.string.isRequired,
      link_id: PropTypes.string.isRequired,
    }),
  }),
}

ItemSingle.defaultProps = {
  item: {
    id: `5fc67108b2b37d04310d0a33`,
    titulo: `Titulo Item`,
    descripcion: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Inventore magnam tempore nobis dolores illo recusandae saepe minima quas eaque corporis provident sint voluptates.`,
    imagen: {
      publicURL: `/static/64a1c492a90e23e4aff745cc9debb823/4fe46ddf8269466efb3d53affd3f7e4c.png`,
    },
    link: {
      nombre: `Link`,
      tipo: `Pagina`,
      link_id: `5fe4770368cc14220204cae4`,
    },
  },
}

export default ItemSingle

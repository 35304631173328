import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Breadcrumb = ({ crumbs }) => {
  return (
    <span id="asm-page-breadcrumb">
      <Link to={`/`}>Home</Link>
      {crumbs.map((item, index) => {
        return index !== crumbs.length - 1 ? (
          <Link to={item.path} key={index}>
            {item.label}
          </Link>
        ) : (
          <span key={index}>{item.label}</span>
        )
      })}
    </span>
  )
}

Breadcrumb.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
}

Breadcrumb.defaultProps = {
  crumbs: [
    {
      path: `/`,
      label: `Home`,
    },
  ],
}

export default Breadcrumb

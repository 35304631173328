import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import ReactMarkdown from "react-markdown"

// Components
import CustomLink from "../../components/elements/custom-link"

import AsmetLogo from "../../assets/images/logos/asmet-color_white@2x.png"

const ContactUs = props => {
  const { footer } = props
  return (
    <div id="asm-contact" className="container-fluid mt-5 bg-blue-alpha">
      <div className="container px-0 px-sm-3 py-3 py-sm-5">
        <div className="row contact-content mb-2">
          {footer.map((linkPpal, index) => {
            return (
              <div
                className={"col-sm text-" + (index === 0 ? "left" : "center")}
                key={index}
              >
                {/* Only if SM or greater */}
                <div className="d-none d-md-block">
                  <h3 className="text-600 text-white-alpha mb-4">
                    <ReactMarkdown
                      source={linkPpal.titulo.nombre}
                      renderers={{ paragraph: "span" }}
                    />
                  </h3>
                  {linkPpal.links ? renderSubLinks(linkPpal.links) : null}
                </div>

                {/* Only if XS */}
                <div
                  className="accordion d-block d-md-none"
                  id={"linksAccordion" + linkPpal.id}
                >
                  <div className="card bg-blue-alpha rounded-0">
                    <div
                      className="card-header bg-blue-alpha"
                      id={"linksHeading" + linkPpal.id}
                    >
                      <Link
                        to={`/`}
                        className="text-left px-0"
                        role="button"
                        data-toggle="collapse"
                        data-target={"#linksCollapse" + linkPpal.id}
                        aria-expanded="false"
                        aria-controls={"linksCollapse" + linkPpal.id}
                      >
                        <h5 className="text-600 text-white-alpha m-0">
                          <ReactMarkdown
                            source={linkPpal.titulo.nombre}
                            renderers={{ paragraph: "span" }}
                          />
                          <i className="fa fa-angle-down float-right"></i>
                        </h5>
                      </Link>
                    </div>
                    <div
                      id={"linksCollapse" + linkPpal.id}
                      className="collapse"
                      aria-labelledby={"linksHeading" + linkPpal.id}
                      data-parent={"#linksAccordion" + linkPpal.id}
                    >
                      <div className="card-body text-left">
                        {linkPpal.links ? renderSubLinks(linkPpal.links) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className="row contact-logos text-center">
          <div className="col-md-4 offset-md-4">
            <Link to="/" className="d-block">
              <img
                src={AsmetLogo}
                alt={"Asmet Main Logo"}
                className="my-4 my-sm-0"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

function renderSubLinks(subLinks) {
  return (
    <ul>
      {subLinks.map((subLink, subIndex) => {
        return (
          <li className="mb-3 mb-sm-2" key={subIndex}>
            <CustomLink
              link={subLink}
              customClass={
                (subLink.icono ? "link-icon " : "") +
                (subLink.nombre.startsWith("Mapa")
                  ? "btn btn-asm-red-alpha rounded-pill px-3 py-2 mt-2 "
                  : "")
              }
            >
              {subLink.icono ? (
                <img
                  src={subLink.icono.publicURL}
                  alt={subLink.nombre}
                  className="mr-2"
                />
              ) : null}
              <ReactMarkdown
                source={subLink.nombre}
                renderers={{ paragraph: "span" }}
              />
              {subLink.nombre.startsWith("Mapa") ? (
                <i className="fa fa-long-arrow-right ml-3"></i>
              ) : null}
            </CustomLink>
          </li>
        )
      })}
    </ul>
  )
}

ContactUs.propTypes = {
  footer: PropTypes.arrayOf(
    PropTypes.shape({
      titulo: PropTypes.shape({
        nombre: PropTypes.string.isRequired,
        tipo: PropTypes.string.isRequired,
        link_id: PropTypes.string.isRequired,
      }).isRequired,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          nombre: PropTypes.string.isRequired,
          tipo: PropTypes.string.isRequired,
          link_id: PropTypes.string.isRequired,
          icono: PropTypes.shape({
            publicURL: PropTypes.string.isRequired,
          }),
        })
      ),
    })
  ).isRequired,
}

ContactUs.defaultProps = {
  footer: [
    {
      titulo: {
        nombre: "Nuestras Políticas",
        tipo: "Grupo",
        link_id: "/",
      },
      links: [
        {
          nombre: "Política de Protección y Datos Personales",
          tipo: "Pagina",
          link_id: "/",
        },
        {
          nombre: "Política y Procedimientos SARLAFT",
          tipo: "Pagina",
          link_id: "/",
        },
        {
          nombre:
            "Notificaciones Judiciales: notificacionesjudiciales@asmetsalud.com",
          tipo: "Pagina",
          link_id: "mailto:notificacionesjudiciales@asmetsalud.com",
        },
      ],
    },
    {
      titulo: {
        nombre: "Destacados",
        tipo: "Pagina",
        link_id: "/",
      },
      links: [],
    },
    {
      titulo: {
        nombre: "De interés",
        tipo: "Pagina",
        link_id: "/",
      },
      links: [],
    },
  ],
}

export default ContactUs
